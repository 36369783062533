// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-jsx": () => import("E:\\Coding Projects\\tyler-drake-website\\src\\templates\\blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-pages-index-jsx": () => import("E:\\Coding Projects\\tyler-drake-website\\src\\pages\\index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-about-index-jsx": () => import("E:\\Coding Projects\\tyler-drake-website\\src\\pages\\about\\index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("E:\\Coding Projects\\tyler-drake-website\\src\\pages\\blog\\index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("E:\\Coding Projects\\tyler-drake-website\\src\\pages\\contact\\index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */)
}

