// Actions
export const SET_IS_MOBILE = 'SET_IS_MOBILE'
export const SET_IS_LANDSCAPE = 'SET_IS_LANDSCAPE'

// Action Creators
export function setIsMobile(isMobile) {
  return { type: SET_IS_MOBILE, isMobile }
}

export function setIsLandscape(isLandscape) {
  return { type: SET_IS_LANDSCAPE, isLandscape }
}

// Reducer
const initialState = {
  isMobile: false,
  isLandscape: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.isMobile,
      }
    case SET_IS_LANDSCAPE:
      return {
        ...state,
        isLandscape: action.isLandscape,
      }
    default:
      return state
  }
}
